import React, { useState, useEffect } from "react";
import Header from "./components/header";
import { motion } from "framer-motion";
import IconArrowDown from "../../assets/icons/icon-arrow-down.svg";
import IconOption from "../../assets/icons/icon-option-item.svg";
import OptimizeImage from "../../assets/icons/optimized.webp";
import FriendlyImage from "../../assets/icons/friendly.webp";
import BusinessImage from "../../assets/icons/business.webp";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

export default function Layout() {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleClick = (index) => {
    setExpandedIndex(index);
  };

  useEffect(() => {
    if (feedbackMessage) {
      const timer = setTimeout(() => {
        setFeedbackMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [feedbackMessage]);

  const onSubmit = (data) => {
    setLoading(true);
    emailjs
      .send("service_idr0j5l", "template_o7z7dmo", data, "co6zMRJ5MYgT_Eyqu")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setFeedbackMessage("Mensagem enviada com sucesso!");
        reset(); // Reset the form after successful submission
      })
      .catch((err) => {
        console.error("FAILED...", err);
        setFeedbackMessage(
          "Falha ao enviar a mensagem. Por favor, tente novamente."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="bg-welcome-image bg-no-repeat bg-cover max-h-[720px] w-full h-full flex flex-col">
        <h1 className="text-white text-4xl md:text-6xl font-monoItalic px-6 md:px-10 py-10 md:py-16">
          Novas idéias para <br />
          outros tempos
        </h1>
        <h2 className="text-white text-2xl md:text-3xl font-monoItalic px-6 md:px-10 py-10 md:py-16">
          Construimos um futuro impulsionado pela
          <br /> tecnologia
        </h2>

        <div className="flex flex-col md:flex-row w-full h-full px-6 md:px-10 pt-16 md:pt-32">
          {[
            "Expanda seu negócio",
            "Conecte-se com mais usuários",
            "Otimize suas soluções",
          ].map((text, index) => (
            <motion.div
              key={index}
              className={`border-solid md:border-t-4 md:border-t-white text-white flex flex-1 mx-4 lg:mx-8 flex-col ${
                expandedIndex === index ? "bg-slate-950" : ""
              }`}
              onClick={() => handleClick(index)}
              animate={{
                height: expandedIndex === index ? "auto" : "144px",
                borderTopColor: expandedIndex === index ? "#020617" : "#fff",
              }}
              whileHover={{ height: expandedIndex === index ? "auto" : "60px" }}
            >
              <span className="cursor-pointer font-sansBlack italic px-4 py-2 text-lg md:text-xl ">
                {text}
              </span>

              {expandedIndex === index && (
                <motion.div
                  className="flex flex-1 lg:mt-6 px-4 overflow-hidden"
                  initial={{ height: "0px" }}
                  animate={{ height: "auto" }}
                  whileHover={{ height: "auto" }}
                >
                  <img
                    src={IconArrowDown}
                    alt="arrow down"
                    className="w-10 h-10 py-2 lg:py-0"
                  />
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>

        <div className="flex flex-col md:flex-row bg-slate-950 px-6 md:px-10 pt-16 md:pt-20 pb-16 md:pb-32">
          {expandedIndex === 2 && (
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-1 flex-col px-6 md:px-10">
                <h3 className="text-white text-2xl md:text-3xl font-monoItalic pb-4">
                  Otimize suas <br /> soluções
                </h3>
                <p className="font-sansLight text-gray-300 text-xl md:text-2xl pt-4 pb-4">
                  Com foco em eficiência e inovação, aprimoramos sua
                  infraestrutura digital para garantir desempenho superior e
                  escalabilidade.
                </p>
                <nav className="flex flex-col pt-8 pb-4">
                  <ul>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Experiência do Usuário Aprimorada </strong>
                        Garantimos que seus clientes tenham uma experiência
                        rápida, intuitiva e responsiva.
                      </span>
                    </li>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Preparado para o Crescimento </strong>Adaptamos
                        suas soluções para suportar o crescimento do seu
                        negócio, oferecendo escalabilidade robusta.
                      </span>
                    </li>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Economia e Eficiência </strong> Reduzimos seus
                        custos operacionais ao implementar soluções eficientes.
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="flex flex-1 w-full justify-center px-6 lg:px-10 md:justify-end">
                <div>
                  <img
                    src={OptimizeImage}
                    alt="Otimize suas soluções"
                    width={467}
                    height={573}
                    className="shadow shadow-indigo-600"
                  />
                </div>
              </div>
            </div>
          )}
          {expandedIndex === 0 && (
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-1 flex-col px-6 md:px-10">
                <h3 className="text-white text-2xl md:text-3xl font-monoItalic pb-4">
                  Expanda seu negócio
                </h3>
                <p className="font-sansLight text-gray-300 text-xl md:text-2xl pt-4 pb-4">
                  Oferecemos soluções especializadas para expandir seu negócio
                  de maneira eficiente e inovadora.
                </p>
                <nav className="flex flex-col pt-8 pb-4">
                  <ul>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Alcance Global </strong>
                        Otimizamos sua presença online para proporcionar uma
                        experiência superior ao usuário em qualquer dispositivo,
                        expandindo seu alcance para novos públicos ao redor do
                        mundo.
                      </span>
                    </li>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Escalabilidade Sem Complicações </strong>
                        Seu negócio cresce conforme sua demanda aumenta,
                        garantindo que sua infraestrutura digital esteja sempre
                        preparada para novos desafios e oportunidades.
                      </span>
                    </li>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Inovação Competitiva </strong> Soluções digitais
                        ágeis e eficientes que não só melhoram a experiência do
                        usuário, mas também impulsionam o crescimento
                        sustentável e rentável do seu negócio.
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="flex flex-1 w-full justify-center px-6 lg:px-10 md:justify-end">
                <div>
                  <img
                    src={BusinessImage}
                    alt="Expanda seu negócio"
                    width={467}
                    height={573}
                    className="shadow shadow-indigo-600"
                  />
                </div>
              </div>
            </div>
          )}
          {expandedIndex === 1 && (
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-1 flex-col px-6 md:px-10">
                <h3 className="text-white text-2xl md:text-3xl font-monoItalic pb-4">
                  Conecte-se com mais usuários
                </h3>
                <p className="font-sansLight text-gray-300 text-xl md:text-2xl pt-4 pb-4">
                  Fortalecemos sua presença digital e a criar conexões
                  significativas com seus usuários.
                </p>
                <nav className="flex flex-col pt-8 pb-4">
                  <ul>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Expansão de Alcance </strong>
                        Ampliamos sua capacidade de alcançar novos usuários e
                        mercados, proporcionando uma base sólida para o
                        crescimento sustentável do seu negócio.
                      </span>
                    </li>
                    <li className="flex py-4">
                      <img src={IconOption} alt="" />
                      <span className="text-base text-gray-300 py-2 px-4">
                        <strong>Engajamento e Fidelização </strong>
                        Criamos estratégias que não apenas atraem novos
                        usuários, mas também fortalecem o relacionamento com os
                        atuais, aumentando a fidelização e o retorno sobre seu
                        investimento digital
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="flex flex-1 w-full justify-center px-6 lg:px-10 md:justify-end">
                <div>
                  <img
                    src={FriendlyImage}
                    alt="Conecte-se com mais usuários"
                    width={467}
                    height={573}
                    className="shadow shadow-indigo-600"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="h-8 bg-slate-950 hidden lg:block">
          <svg
            className="w-full"
            viewBox="0 0 803 24"
            fill="#020617"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M402.749 14.2345C226.459 14.3669 58.1305 8.05517 0 0V24H402.749H803V0C742.205 7.94483 579.039 14.1021 402.749 14.2345Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="flex flex-col justify-center items-center py-16 md:py-32">
          <h1 className="font-sansBold text-3xl md:text-5xl py-4 md:py-8 text-center">
            Fale com a equipe de Vendas
          </h1>
          <p className="font-sansLight text-lg md:text-2xl text-gray-800 text-center">
            Nós vamos te ajudar a encontrar o plano e <br />
            preços para o seu negócio.
          </p>

          <form
            className="flex flex-col w-full max-w-2xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col w-full max-w-2xl px-6 md:px-10 py-8 md:py-16">
              {feedbackMessage && (
                <div className="mb-4 text-center text-lg text-white bg-slate-950 p-4 w-full">
                  {feedbackMessage}
                </div>
              )}
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="font-sansLight text-lg md:text-xl py-4"
                >
                  E-mail da sua Empresa
                </label>
                <input
                  id="email"
                  type="text"
                  className="w-full border-solid border border-gray-300 py-3 px-4 text-base outline-none"
                  {...register("email", {
                    required: "Este campo é obrigatório",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Endereço de e-mail inválido",
                    },
                  })}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="helpyou"
                  className="font-sansLight text-lg md:text-xl py-4"
                >
                  Como podemos ajudar seu negócio?
                </label>
                <textarea
                  id="helpyou"
                  className="w-full border-solid border border-gray-300 py-3 px-4 text-base outline-none resize-none"
                  cols={30}
                  rows={10}
                  {...register("message", {
                    required: "Este campo é obrigatório",
                  })}
                ></textarea>
                {errors.message && (
                  <span className="text-red-500">{errors.message.message}</span>
                )}
              </div>
              <div className="flex flex-col mt-8">
                <input
                  className={
                    "bg-slate-950 text-white cursor-pointer py-3 px-4 text-base outline-none text-xl" +
                    (loading ? " opacity-50" : "")
                  }
                  type="submit"
                  disabled={loading}
                  value="Solicitar contato"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
