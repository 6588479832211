import React from "react";

import IconLogo from "../../../../assets/icons/icon-logo.svg";

export default function Header() {
  return (
    <div className="color-black text-white flex justify-between items-center w-full h-16 px-12 py-10 bg-slate-950">
      <img src={IconLogo} alt="logo" className="w-76 h-42" />
    </div>
  );
}
