import React from "react";

import Home from "./screens/home/layout";
import { Analytics } from "@vercel/analytics/react";

export default function App() {
  return (
    <div className="flex flex-col">
      <Home />
      <Analytics />
    </div>
  );
}
